*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 90%;
}

body,
#app {
	box-sizing: border-box;
	font-family: Roboto, sans-serif !important;
	font-weight: 400 !important;
}

html,
body,
#app,
#app > div {
	height: 100%;
	width: 100%;
}

/* Hide default scrollbars but allow scrolling */
body {
	scrollbar-width: none !important; /* Firefox */
	-ms-overflow-style: none !important; /* IE and Edge */
	overflow: scroll !important; /* Fallback for other browsers */
}

/* Create a custom scrollbar appearance */
body::-webkit-scrollbar {
	width: 0 !important; /* Hide the width of the scrollbar */
}
